@import '~react-image-gallery/styles/css/image-gallery.css';

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 3rem;
  width: 2rem;
}
.image-gallery {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.image-gallery-icon:hover {
  color: #97b539;
}
.image-gallery-image {
  width: unset;
  flex: 1 1 100%;
  display: flex;
  object-fit: scale-down !important;
}
.image-gallery-swipe {
  height: 100%;
}
.ant-tabs-content-holder {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.image-gallery-content {
  flex-direction: column !important;
  display: flex !important;
  height: 100%;
}
.media-tab {
  display: flex;
  flex-direction: column;
}
.image-gallery-slide-wrapper {
  height: 100%;
  flex: 1;
  display: flex;
  overflow: hidden;
  flex-direction: column;
}
.image-gallery-slides {
  display: flex;
  flex: 1;
  height: 100%;
}
.image-gallery-slide.center {
  display: flex !important;
  position: unset;
  justify-content: center;
}
.image-gallery-slide {
  flex: 1 1 100%;
  height: 100%;
  justify-content: center;
}
.image-gallery-thumbnail-inner {
  cursor: pointer;
}

/* styling chonky.io File Browser */
.chonky-chonkyRoot,
.chonky-chonkyRoot p,
.chonky-chonkyRoot span,
.chonky-chonkyRoot div,
.chonky-chonkyRoot input {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji' !important;
}
.chonky-toolbarRight span {
  font-weight: 400 !important;
}
.chonky-navbarWrapper {
  display: none;
}
